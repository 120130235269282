<template>
  <div class="warp-box" @scroll="msgScroll">
    <v-tabs v-model="tab">
      <div
        style="
          width: 100%;
          position: absolute;
          bottom: 0;
          background-color: #c2c2c2;
          height: 2px;
        "
      ></div>

      <v-tab
        v-for="(item, index) in items"
        :key="item.value"
        @click="changeTab(item.value)"
      >
        <div
          :style="{
            color:
              tab === index ? $vuetify.theme.themes.light.primary.base : '',
          }"
          class="tab-title"
        >
          {{ item.name }}
        </div>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.value">
        <div v-for="msgItem in msgList" :key="msgItem.id">
          <v-hover>
            <template #default="{ hover }">
              <div
                :class="{ 'msg-item-hover': hover }"
                class="py-2 px-5 msg-item-warp d-flex align-center"
                style="cursor: pointer"
                @click="clickMsg(msgItem)"
              >
                <div class="d-flex align-center">
                  <div
                    style="background-color: #a6beee"
                    class="rounded-circle pa-2"
                  >
                    <v-badge :value="msgItem.status === '02'" color="error" dot>
                      <v-icon color="#F1F5FC">mdi-bell</v-icon>
                    </v-badge>
                  </div>
                  <div class="ml-5">
                    <div>
                      {{ msgItem.content }}
                    </div>
                    <div class="text-subtitle-1">
                      {{ msgItem.createdTime }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </v-hover>
        </div>
        <div
          v-if="msgList.length <= 0"
          class="text-center my-7"
          style="color: rgba(0, 0, 0, 0.38); font-size: 0.875rem"
        >
          暂无更多数据!
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      tab: null,
      items: [
        { name: '全部消息', value: ' ' },
        { name: '未读消息', value: 'N' },
      ],
      msgList: [],
      total: 0,
      pageNo: 1,
      pageSize: 20,
      pageTotal: 0,
    };
  },
  created() {
    this.getMsgLogList(' ');
  },
  methods: {
    msgScroll(event) {
      const e = event.target;
      if (
        parseInt(e.scrollHeight) -
          (parseInt(e.clientHeight) + parseInt(e.scrollTop)) ===
        0
      ) {
        console.log(this.pageNo, this.pageTotal);
        this.pageNo++;
        if (this.pageNo > this.pageTotal) return;
        this.getMsgLogList(this.items[this.tab].value);
      }
    },
    changeTab(tab) {
      this.pageNo = 1;
      this.msgList = [];
      this.getMsgLogList(tab);
    },
    getMsgLogList(read) {
      let param = {
        read: read,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      this.$api.mine.mine.getmessger(param).then((res) => {
        console.log(res);
        const list = res.data || [];
        const total = res.total;
        if (total % this.pageSize) {
          this.pageTotal = parseInt(total / this.pageSize) + 1;
        } else {
          this.pageTotal = parseInt(total / this.pageSize);
        }
        this.msgList = this.msgList.concat(list);
      });
    },
    clickMsg(item) {
      let param = {
        uuid: item.uuid,
      };

      this.$api.mine.mine.getread(param).finally(() => {
        if (this.tab === 1) {
          this.msgList.map((val, index) => {
            if (val.uuid === item.uuid) {
              this.$set(val, 'status', '01');
              this.msgList.splice(index, 1);
            }
          });
        } else {
          this.$set(item, 'status', '01');
          // this.msgList = [];
          // this.pageNo--;
          // this.getMsgLogList(this.items[this.tab].value);
        }
        this.$store.dispatch('getMsg');
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.warp-box {
  height: calc(100vh - 50px - 50px - 65px);
  overflow: auto;
  overflow-x: hidden;
}
.text-body-2 {
  font-size: 10px !important;
}
.tab-title {
  font-size: 18px;
  font-weight: 400;
  //color: #606266;
}
.text-subtitle-1 {
  font-size: 12px !important;
}
.msg-item-warp {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }
}
.msg-item-hover {
  background-color: #f6f6f6;
  //&::before {
  //  border: 1px red solid;
  //}
}
</style>
